var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"400px"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editPump)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:" font-weight-bold blue-grey--text font"},[_vm._v("Edit Pump")]),_c('v-spacer')],1),(_vm.progress)?_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center pb-4"},[_c('v-progress-circular',{attrs:{"color":"primary","size":"40","width":"3","indeterminate":""}})],1):[_c('v-card-text',[_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"outlined":"","label":"Name of Pump","error-messages":errors[0]},model:{value:(_vm.pump.name),callback:function ($$v) {_vm.$set(_vm.pump, "name", $$v)},expression:"pump.name"}})]}}],null,true)}),_c('span',{staticClass:"font mt-n3 font-weight-medium font-size-sm text-uppercase"},[_vm._v("Assign Fuels To Pumps")]),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"}},[_c('v-autocomplete',{staticClass:"font black--text font-weight-medium font-size-sm",attrs:{"solo":"","search-input":_vm.search,"dense":"","items":_vm.products,"item-value":"id","item-text":"name","color":"primary","clearable":"","return-object":"","background-color":"grey lighten-4","append-icon":"search","flat":"","placeholder":"Search for products by using their name ..."},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{staticClass:"font font-weight-medium"},[_vm._v(" Search through all "),_c('strong',[_vm._v("fuels")])])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),(_vm.addedNozzles.length > 0)?_vm._l((_vm.addedNozzles),function(product,index){return _c('div',{key:("prod-" + index),staticClass:"d-flex flex-row flex-grow-1 mt-n5 px-1 pt-4 grey lighten-4"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 font font-weight-medium font-size-sm",attrs:{"dense":"","label":"Product","outlined":"","readonly":"","error-messages":errors[0]},model:{value:(product.name),callback:function ($$v) {_vm.$set(product, "name", $$v)},expression:"product.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 font font-weight-medium font-size-sm",attrs:{"dense":"","outlined":"","label":"Number Of Nozzles","error-messages":errors[0]},model:{value:(product.numberOfNozzles),callback:function ($$v) {_vm.$set(product, "numberOfNozzles", $$v)},expression:"product.numberOfNozzles"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-1",attrs:{"text":"","color":"red","small":"","icon":"","disabled":"","fab":""}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("remove")])])],1)}):_vm._e(),(_vm.pump.productId.length > 0)?_vm._l((_vm.pump.productId),function(product,index){return _c('div',{key:index,staticClass:"d-flex flex-row flex-grow-1 mt-n5 px-1 pt-4 grey lighten-4"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 font font-weight-medium font-size-sm",attrs:{"dense":"","label":"Product","outlined":"","readonly":"","error-messages":errors[0]},model:{value:(product.name),callback:function ($$v) {_vm.$set(product, "name", $$v)},expression:"product.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 font font-weight-medium font-size-sm",attrs:{"dense":"","outlined":"","label":"Number Of Nozzles","error-messages":errors[0]},model:{value:(product.numberOfNozzles),callback:function ($$v) {_vm.$set(product, "numberOfNozzles", $$v)},expression:"product.numberOfNozzles"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-1",attrs:{"text":"","color":"red","small":"","icon":"","fab":""},on:{"click":function($event){return _vm.pump.productId.splice(index, 1)}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("remove")])])],1)}):[_c('div',{staticClass:"d-flex flex-column flex-grow-1 pt-4",staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":require("@/assets/shopping-basket.svg")}}),_c('span',{staticClass:"font pt-1 text-center font-weight-medium font-size-sm"},[_vm._v(" Click on a "),_c('kbd',[_vm._v("search")]),_vm._v(" results to assign nozzles ")])])]],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:" font font-weight-medium font-size-sm",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"font font-weight-medium font-size-sm",attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading || _vm.progress}},[_vm._v(" Save ")])],1)]],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }