<template>
  <v-dialog v-model="state" persistent width="400px">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form ref="form" @submit.prevent="handleSubmit(editPump)">
        <v-card>
          <v-card-title>
            <span class=" font-weight-bold blue-grey--text font"
              >Edit Pump</span
            >
            <v-spacer></v-spacer>
          </v-card-title>
          <div
            v-if="progress"
            class="d-flex flex-row flex-grow-1 justify-center pb-4"
          >
            <v-progress-circular
              color="primary"
              size="40"
              width="3"
              indeterminate
            />
          </div>
          <template v-else>
            <v-card-text>
              <ValidationProvider
                tag="div"
                class="d-flex flex-column flex-grow-1"
                v-slot="{ errors }"
                rules="required"
              >
                <v-text-field
                  outlined
                  v-model="pump.name"
                  label="Name of Pump"
                  class="font font-weight-medium"
                  :error-messages="errors[0]"
                />
              </ValidationProvider>
              <span
                class="font mt-n3 font-weight-medium font-size-sm text-uppercase"
                >Assign Fuels To Pumps</span
              >
              <div class="d-flex flex-column flex-grow-1">
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                >
                  <v-autocomplete
                    solo
                    :search-input.sync="search"
                    dense
                    :items="products"
                    item-value="id"
                    item-text="name"
                    color="primary"
                    v-model="query"
                    clearable
                    return-object
                    background-color="grey lighten-4"
                    append-icon="search"
                    class="font black--text font-weight-medium font-size-sm"
                    flat
                    placeholder="Search for products by using their name ..."
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title class="font font-weight-medium">
                          Search through all
                          <strong>fuels</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="font font-weight-medium font-size-sm">
                        {{ item.name }}
                      </span>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
                <template v-if="addedNozzles.length > 0">
                  <div
                    v-for="(product, index) in addedNozzles"
                    :key="`prod-${index}`"
                    class="d-flex flex-row flex-grow-1 mt-n5 px-1 pt-4 grey lighten-4"
                  >
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-column"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        dense
                        label="Product"
                        outlined
                        class="pa-1 font font-weight-medium font-size-sm"
                        v-model="product.name"
                        readonly
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-column"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        dense
                        outlined
                        v-model="product.numberOfNozzles"
                        class="pa-1 font font-weight-medium font-size-sm"
                        label="Number Of Nozzles"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <v-btn
                      text
                      color="red"
                      small
                      icon
                      disabled
                      fab
                      class="mt-1"
                    >
                      <i class="material-icons-outlined">remove</i>
                    </v-btn>
                  </div>
                </template>
                <template v-if="pump.productId.length > 0">
                  <div
                    v-for="(product, index) in pump.productId"
                    :key="index"
                    class="d-flex flex-row flex-grow-1 mt-n5 px-1 pt-4 grey lighten-4"
                  >
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-column"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        dense
                        label="Product"
                        outlined
                        class="pa-1 font font-weight-medium font-size-sm"
                        v-model="product.name"
                        readonly
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-column"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        dense
                        outlined
                        v-model="product.numberOfNozzles"
                        class="pa-1 font font-weight-medium font-size-sm"
                        label="Number Of Nozzles"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <v-btn
                      text
                      color="red"
                      @click="pump.productId.splice(index, 1)"
                      small
                      icon
                      fab
                      class="mt-1"
                    >
                      <i class="material-icons-outlined">remove</i>
                    </v-btn>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="d-flex flex-column flex-grow-1 pt-4"
                    style="align-items: center;"
                  >
                    <img
                      src="@/assets/shopping-basket.svg"
                      style="width: 60px"
                    />
                    <span
                      class="font pt-1 text-center font-weight-medium font-size-sm"
                    >
                      Click on a <kbd>search</kbd> results to assign nozzles
                    </span>
                  </div>
                </template>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                @click="close"
                text
                class=" font font-weight-medium font-size-sm"
              >
                Close
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                :loading="loading"
                :disabled="loading || progress"
                class="font font-weight-medium font-size-sm"
              >
                Save
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { debounce } from "lodash";
extend("required", {
  ...required,
  message: "Input field is required"
});
const even = {
  getMessage(field, args) {
    return "Field must contain even numbers eg. 2,4,6,8";
  },
  validate(value, args) {
    return value % 2 === 0;
  }
};
extend("numeric", {
  ...numeric,
  message: `Input must only be numbers`
});

extend("even", {
  ...even,
  message: "Values should be 2,4,6,8 ..."
});
export default {
  name: "AddNewPump",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: () => []
    },
    details: {
      type: Object,
      default: null
    }
  },

  watch: {
    details(payload) {
      const { name, id, pumpProductId, nozzles } = { ...payload };
      let products = pumpProductId?.map(pump => {
        return {
          id: pump.productId?.id,
          name: pump.productId?.name,
          numberOfNozzles: nozzles.filter(
            nozzle => nozzle.productId?.id === pump.productId?.id
          ).length
        };
      });
      this.pump = {
        id,
        name,
        productId: [],
        nozzleId: []
      };
      this.addedNozzles = products;
    },
    search: debounce(function(value) {
      this.$store.dispatch("products/search", { search: value });
    }, 500),
    query(value) {
      if (value) {
        const { name, id } = value;
        const isNozzleAdded = this.addedNozzles.find(product => {
          return product.id === id;
        });
        if (!isNozzleAdded) {
          this.pump.productId.push({ id, name, numberOfNozzles: 2 });
        }
      }
    }
  },
  data: () => ({
    pump: {
      name: "",
      productId: []
    },
    nozzleId: [],
    search: null,
    query: null,
    addedNozzles: []
  }),
  methods: {
    editPump() {
      const { id, name, productId } = this.pump;
      let payload = {
        id,
        name,
        productId: productId?.map((product, index) => {
          return {
            ...product,
            index: index + this.addedNozzles.length
          };
        })
      };
      this.$store.dispatch("pumps/update", payload);
    },
    close() {
      this.$emit("close", "edit");
    }
  }
};
</script>
